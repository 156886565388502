$theme-colors: (
  "95": #0a0,
  "dizel": #444,
  "primary": #1f6d7f,
  "dark": #1A4959,
);

@import "../clientapp/node_modules/bootstrap/scss/bootstrap";

@import "./mixins.scss";
@import "./cruds.scss";
@import "./cruds-table-fields.scss";

@import "./changeset.scss";
@import "./offcanvas.scss";
@import "./prices.scss";
@import "./search.scss";
@import "./places.scss";
@import "./results.scss";
@import "./filters.scss";
@import "./select2.scss";
@import "./dashboard.scss";
@import "./accounts.scss";
@import "./custom-forms.scss";
@import "./disclaimer.scss";


.gap-2 {
  gap: 0.25rem;
}

.navbar {
  padding-top: calc(0.5rem + env(safe-area-inset-top));
}
body {
  padding-top: calc(56px + env(safe-area-inset-top));
}
.offcanvas-collapse {
  top: calc(56px + env(safe-area-inset-top));
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: #ffffff99;
  display: none;
  align-items: center;
  justify-content: center;
}
.hx-request .loading {
  display: flex;
}
.loading__spinner {
  position: absolute;
  width: 48px;
  height: 48px;
  color: $secondary;
  opacity: 1;
}


//SPINNER SVG ELEMENT
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

.spinner-border {
  border-width: 0.25em !important;
}

