.search {
}
.search__form {
  @extend .py-2;
  @extend .px-1;
  background: $gray-300;
}
.search__map {
}
.search__results {
}

//IE11
@include media-breakpoint-up(md) {
  .search {
    position: absolute;
    top: 56px;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .search__form {
    position: absolute;
    width: 450px;
    height: 231px;
  }
  .search__map {
    position: absolute;
    left: 470px;
    top: 0;
    right: 0;
    bottom: 0;
  }
  .search__results {
    position: absolute;
    top: 220px;
    bottom: 0;
    width: 450px;
    overflow-y: auto;
  }
}

@supports (display: grid) {
  @include media-breakpoint-up(md) {
    .search {
       //reset old browsers code
      position: static; top: auto; left: auto; bottom: auto; right: auto;

      display: grid;
      grid-template-columns: [sidebar] 5fr [main] 5fr;
      grid-template-rows: auto 1fr;
      height: calc(100vh - 56px - 59px);
      grid-gap: 20px;
      grid-template-areas:
        'form map'
        'results .';
    }
    .search__form {
       //reset old browsers code
      position: static; top: auto; left: auto; bottom: auto; right: auto;
      grid-area: form;
    }
    .search__map {
       //reset old browsers code
      position: static; top: auto; left: auto; bottom: auto; right: auto;
      grid-column: map;
      grid-row: 1 / span 2;
    }
    .search__results {
       //reset old browsers code
      position: static; top: auto; left: auto; bottom: auto; right: auto;
      grid-area: results;
      overflow-y: auto;
    }
  }
  @include media-breakpoint-up(lg) {
    .search {
      grid-template-columns: [sidebar] 450px [main] 5fr;
    }
  }
}

.map {
}
.map__container {
  height: 400px;
}

@include media-breakpoint-up(md) {
  .map__container {
    height: 100%;
  }
}

.search-form {
}
.search-form__row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.search-form__row--nowrap {
  flex-wrap: nowrap;
}
.search-form__form-group {
  flex: 1 1 auto;
  margin: 0 5px;
  @extend .my-2;
}
.search-form__position-input-group {
  flex-wrap: nowrap;
}
.search-form__position {
  flex: 2 1 auto;
}
.search-form__position-input {
  flex: 1 1 auto;
}
.search-form__button-text {
  display: none;
  @include media-breakpoint-up(sm) {
    display: inline;
  }
}
.search-form__radius {
  flex: 0 2 auto;
}

.search-form__search-btn {
  width: 100%;
  @include media-breakpoint-up(sm) {
    width: auto;
  }
}
