.fuel-price {
  width: 80px;
  @extend .mb-1;
  @extend .mx-1;
  flex: 0 0 auto;
}
.fuel-price__price {
  @extend .rounded-top;
  @extend .p-1;
  width: 100%;
  text-align: center;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  border: 1px solid #999;
  border-bottom: none;
}
.fuel-price__label {
  @extend .rounded-bottom;
  @extend .px-1;
  width: 100%;
  font-size: 65%;
  line-height: 1.3;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #333;
  border: 1px solid #999;
}
@each $color, $value in $theme-colors {
  .fuel-price--#{$color} {
    .fuel-price__price {
      background: $value;
      border: none;
      color: #fff;
    }
    .fuel-price__label {
      border-top: none;
    }
  }
}
