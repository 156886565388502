.results {
}
.search__warning-select-location {
  padding: 20px 23px;
}
.results--opened {
  .results__item {
    display: none;
  }
  .results__item--selected {
    display: block;
  }
}

.results__item {
  cursor: pointer;
  background: $gray-200; 
  &:hover {
    background: #fff;
  }
}
.results__item--selected {
  cursor: auto;
  background: #fff;
  padding-top: 1.5rem;
  padding-bottom: 3rem;
}


.fuel-price {
}
.fuel-price__label {
}
.fuel-price__price {
}

