.dashboard__content {
  @include media-breakpoint-up(xl) {
    max-width: 1400px;
  }
}
.dashboard__sidebar {
  @include media-breakpoint-up(md) {
    border-right: 1px solid rgba(0,0,0,.1);
    min-height: calc(100vh - 60px);
  }
  @include media-breakpoint-up(xl) {
    flex: 0 1 320px;
  }
}

.sidebar {
  top: 60px;

  .nav {
    > .nav-item {
      > .active {
        font-weight: $font-weight-bold;
      }
    }
  }

}
@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar {
    position: -webkit-sticky;
    position: sticky;
  }
}
