.place {
}
.place__header-actions {
  cursor: pointer;
  @extend .d-flex;
  @extend .mb-4;
}
.place__header {
}
.place__title {
}
.place__prices {
  @extend .mb-1;
  margin-left: -($spacer * .25);
  margin-right: -($spacer * .25);
  display: flex;
  flex-wrap: wrap;
}
.place__description {
}
.place__additional {
  @extend .my-4;
}
.place__open-hours {
}
.place__open-hours-description {
  white-space: pre-line;
}
.place__direction {
}
.place__directions {
}
